import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import ArrowDown from "../images/arrow_right_black.inline.svg";
import { Styling } from "../styles/pages/programmes-production.styled";
import parse from "html-react-parser";
import Seo from "../components/global/seo";
import TemplateCard from "../components/reusable/TemplateCard";

export default function Programmes(props) {
  return (
    <Styling>
      <Seo title={props.data.wpPage.seo.title} description={props.data.wpPage.seo.metaDesc} />
      <div className="intro">
        <h2 className="main-title">
          <span>Toolroom</span>{" "}
          <span>
            <ArrowDown className="arrow title-arrow" /> Templates
          </span>
        </h2>
        <div className="intro_text">
          {parse(props.data.wpPage.programme_archive_fields_production.introText)}
        </div>
        <div className="intro_buttons">
          <Link to="#logic" className={`capsule_button white`}>
            Logic Templates
          </Link>
          <Link to="#ableton" className={`capsule_button white`}>
            Ableton Templates
          </Link>
        </div>
      </div>
      <div id="logic" className="courses-wrapper artist">
        <h2>Logic Templates</h2>
        <div className="courses">
          {props.data.logic.nodes.map((template, index) => {
            return <TemplateCard template={template} />;
          })}
        </div>
      </div>
      <div id="ableton" className="courses-wrapper foundation">
        <h2>Ableton Templates</h2>
        <div className="courses">
          {props.data.ableton.nodes.map((template, index) => {
            return <TemplateCard template={template} />;
          })}
        </div>
      </div>
    </Styling>
  );
}

export const query = graphql`
  {
    wpPage(title: { eq: "Toolroom Templates" }) {
      title
      programme_archive_fields_production {
        introText
      }
      seo {
        metaDesc
        title
      }
    }

    ableton: allWpProduct(
      sort: { fields: menuOrder, order: ASC }
      filter: { templateCategories: { nodes: { elemMatch: { name: { eq: "Ableton" } } } } }
    ) {
      nodes {
        title
        slug
        acf_product_info {
          templates {
            price
            pluginThumbnailImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
        }
      }
    }
    logic: allWpProduct(
      sort: { fields: menuOrder, order: ASC }
      filter: { templateCategories: { nodes: { elemMatch: { name: { eq: "Logic" } } } } }
    ) {
      nodes {
        title
        slug
        acf_product_info {
          templates {
            price
            pluginThumbnailImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
              }
            }
          }
        }
      }
    }
  }
`;
