import React from "react";
import { Styling } from "./styled.js";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import formatIntructors from "../../../utils/formatInstructors.js";

export default function TemplateCard({ template }) {
  const theImage = getImage(template?.acf_product_info?.templates?.pluginThumbnailImage?.localFile);
  return (
    <Styling>
      <Link to={`/shop/${template.slug}`}>
        <div className="image-wrapper">
          <GatsbyImage image={theImage} className="image" alt={"test"} />
          <div className="price">£{template.acf_product_info.templates.price}</div>
        </div>
        <div className="info">
          <h1 className="course_title">{template.title}</h1>
        </div>
      </Link>
    </Styling>
  );
}
